<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" hide-default-header loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

    <template v-slot:header="{ props }">
        <thead>
        <tr>
            <th rowspan="3">NO</th>
            <th rowspan="3">ID REGISTER</th>
            <th rowspan="3">REGISTER</th>
            <th rowspan="3">TOTAL</th>
            <th :colspan="Object.keys(tbGiatName).length" class="text-center">KEGIATAN</th>
        </tr>
        <tr>
            <template v-for="(head,k) in props.headers">
                
                <!-- <th :key="k" rowspan="3" v-if="head.text.toUpperCase() == 'NO'">{{ head.text.toUpperCase() }}</th>
                <th :key="k" rowspan="3" v-else-if="head.text.toUpperCase() == 'ID REGISTER'">{{ head.text.toUpperCase() }}</th>
                <th :key="k" rowspan="3" v-else-if="head.text.toUpperCase() == 'REGISTER'">{{ head.text.toUpperCase() }}</th>
                <th :key="k" rowspan="3" v-else-if="head.text.toUpperCase() == 'TOTAL'">{{ head.text.toUpperCase() }}</th> -->
                <th class="text-center" :key="k" v-if="head.text.toUpperCase() != 'NO' 
                && head.text.toUpperCase() != 'ID REGISTER' 
                && head.text.toUpperCase() != 'REGISTER'
                && head.text.toUpperCase() != 'TOTAL' ">{{ head.text.toUpperCase() }}</th>

            </template>
        </tr>
        <tr>
            <template v-for="(head,k) in tbGiatName">
                <th :key="k" class="text-center">{{head}}</th>

            </template>
        </tr>
         
        </thead>

       
      </template>

            <template v-slot:item.noRow="{ item }">
                <div v-if="item.registerId">
                    {{tbData.map(function(x) {return x.registerId; }).indexOf(item.registerId)+1}}

                </div>
            </template>
            <template v-slot:item.total="{item}">
                <div v-if="item.total > 0" class="p-2 text-right">
                    {{G_numFormat(item.total)}}
                </div>
            </template>

            <template v-for="(slot,i) in tbData[0].pagu" v-slot:[`item.pagu[${i}]`]="{ item }">
                {{G_numFormat(item.pagu[i])}}
            </template>

            <template slot="body.append">
                <tr style="background: #039be5;color: #fff;font-weight: 500;">
                    <td colspan="3" style="font-size:12px;">Total</td>
                    <td class="text-right" style="font-size:12px;">{{G_numFormat(tbGrandTotal)}}</td>
                    <template v-for="(slot,i) in tbTotal">
                        <td :key="i" class="text-right" style="font-size:12px;">{{ G_numFormat(slot) }}</td>
                    </template>

                </tr>
            </template>

        </v-data-table>



    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,

            }
        },
        mounted() {

        },
        methods: {
            sumField(key) {
                // sum data in give key (property)
                return this.tbData.reduce((a, b) => a + (b[key] || 0), 0)
            },

            getTable() {
                this.$emit('getTable');
            },
            edit(id) {
                this.$emit('edit', id);
            },
            hapus(satkerId, kdgiat) {
                this.$emit('hapus', satkerId, kdgiat);
            },


        },

        props: ['tbData', 'headers', 'search', 'loadingTb', 'tbTotal', 'tb_etc','tbGrandTotal','tbGiatName'],
    }
</script>