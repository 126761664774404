import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      list_usersTypes: [],
      list_userGroup: [],
      isBalai: false,
      isSatker: false,
      listGiat: [],
      listSatker: [],
      listEvent: [],
      tb_etc: [],
      headers: [],
      tbTotal: [],
      tbGiatName: [],
      tbGrandTotal: [],

      uploadRule: [(v) => !!v || "Tidak Boleh Kosong"],
      files: [],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.$nextTick(() => {
      this.getTable();

      // this.G_GetAny("sakti/event").then((res) => {
      //   let resData = res.data.content;

      //   let listData = [];
      //   let arrSub = {};
      //   let setDefault = null;

      //   resData.forEach((v) => {
      //     if (v.asDefault) {
      //       setDefault = v.saktiEventId;
      //     }

      //     arrSub = {};

      //     arrSub["text"] = v.saktiEventNm;
      //     arrSub["value"] = v.saktiEventId;

      //     listData.push(arrSub);
      //   });

      //   this.listEvent = listData;

      //   // this.listEvent.unshift({
      //   //   'text': 'Semua Event',
      //   //   'value': 'all'
      //   // })
      //   this.$refs.event.setValue(setDefault);

      // });
    });
  },
  components: {
    myTable,
  },
  methods: {
    downloadTemplate() {
      window.open(
        process.env.VUE_APP_URL_API + "/download/PaguArahanPusat.xlsx",
        "_blank"
      );
    },
    getTable() {
      // let filter = []

      let filter = this.$route.query.eventId;
      // if (this.$refs.event.getValue()) {
      //   filter = this.$refs.event.getValue();
      //   // if (this.$refs.event.getValue() != 'all') {
      //     filter.push('saktiEventId=' + this.$refs.event.getValue())
      //   // }
      // }
      // filter.push("saktiEventId=" + this.$route.query.eventId);

      // filter = '?'+filter.join('&')

      this.loadingTb = true;
      this.G_GetAny("pagu-arahan-pusat/" + filter).then((res) => {
        this.loadingTb = false;
        this.tbData = res.data.content;
        this.tbGiatName = res.data.nmpembinas;

        let tmpHead = res.data.kdgiats;

        (this.headers = [
          {
            text: "No",
            value: "noRow",
            sortable: false,
            width: "1%",
            rowspan: "3",
          },
          { text: "ID Register", value: "registerId", rowspan: "3" },
          { text: "Register", value: "registerNm" },
          { text: "Total", value: "total" },
        ]),
          tmpHead.forEach((v) => {
            this.headers.push({
              text: v,
              value: "pagu[" + v + "]",
              align: "right",
            });
          });

        // let pagu {};
        let tmpTotal = res.data.paguTotal;

        this.tbTotal = tmpTotal;
        this.tbGrandTotal = res.data.grandPaguTotal;

        //   this.tbData.push(  {
        //     "pagu": tmpTotal,
        //     "total": ''
        // },);
      });
    },

    edit(kdsatker, kdgiat) {
      console.log(id);
      this.G_GetAny(`direktorat-giat/${kdsatker}/${kdgiat}`).then((res) => {
        this.getData = res.data;
        // this.setId = res.data.userId

        this.$refs.modal.open("Edit Data Kegiatan per Direktorat", 600);

        this.$nextTick(() => {});
      });
    },
    upload() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.setId = null;
      this.getData = [];
      this.$refs.modal.open("Upload Data Pagu Arahan Pusat", 600);
    },
    store() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.append("kdsatker", this.$refs.kdsatker.getValue());
      formData.append("kdgiat", this.$refs.kdgiat.getValue());

      if (this.setId) {
        formData.append("id", this.setId);
      }

      if (this.$refs.form.validate()) {
        this.$refs.modal.loading();

        if (this.setId) {
          this.G_PutAny("direktorat-giat", formData)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Mengubah Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning(res.data.message, "Terjadi Kesalahan");
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        } else {
          this.G_PostAny("direktorat-giat", formData)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Menyimpan Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning(res.data.message);
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        }
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    async uploadPaguPusat() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.set("sakti_event_id", this.$route.query.eventId);

      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);

        this.G_PostAnyCustom("/pagu/arahan/upload/pusat", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              // this.$refs.sakti_event_id.setValue("");
              this.files = [];
              this.$refs.modal.close();
              this.$snotify.success("Berhasil Upload Data");
            } else {
              this.$snotify.warning(res.data.message);
            }
          })
          .finally(() => {
            this.$refs.btnAct.load(false);
          });
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    async hapus(kdsatker, kdgiat) {
      let judul = "Hapus Data";
      let subJudul = "Yakin Akan Hapus Data Ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();
        this.G_DeleteAny(`direktorat-giat/${kdsatker}/${kdgiat}`)
          .then((res) => {
            if (res) {
              this.$refs.confirm.close();
              this.$refs.modal.close();
              this.getTable();
              this.$snotify.success(res.message);
              // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
            } else {
              this.$snotify.warning(res.message);

              // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },
  },
};
